.calendar-heatmap {
    user-select: none;
    -ms-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
}
.calendar-heatmap .item {
    cursor: pointer;
}
.calendar-heatmap .label {
    cursor: pointer;
    fill: #aaa;
    font-family: Helvetica, arial, Open Sans, sans-serif;
}
.calendar-heatmap .button {
    cursor: pointer;
    fill: transparent;
    stroke-width: 2;
    stroke: #aaa;
}
.calendar-heatmap .button text {
    stroke-width: 1;
    text-anchor: middle;
    fill: #aaa;
}
.calendar-heatmap .heatmap-tooltip {
    pointer-events: none;
    position: absolute;
    z-index: 1;
    width: 250px;
    max-width: 250px;
    overflow: hidden;
    padding: 15px;
    font-size: 12px;
    line-height: 14px;
    color: #333;
    font-family: Helvetica, arial, Open Sans, sans-serif;
    background: hsla(0, 0%, 100%, 0.75);
}
.calendar-heatmap .heatmap-tooltip .header strong {
    display: inline-block;
    width: 250px;
}
.calendar-heatmap .heatmap-tooltip span {
    display: inline-block;
    width: 50%;
    padding-right: 10px;
    box-sizing: border-box;
}
.calendar-heatmap .heatmap-tooltip .header strong,
.calendar-heatmapg .heatmap-tooltip span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
